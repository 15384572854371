import {Row,Col} from 'antd';
import dayjs from 'dayjs';
import { useSelector} from 'react-redux';
import '../tablas/TablasResultados.css';
import './Templates.css';
// import 'dayjs/locale/ja'
import utc from "dayjs/plugin/utc";


export const ContadorComponent = () => {
    // dayjs.locale('es-mx')
    dayjs.extend(utc);
    const competition = useSelector(state => state.competitionsStore.competition);
    // console.log(dayjs(competition?.startDate));
    // console.log(dayjs(competition?.startDate).utc());
    // console.log(dayjs());
    // console.log(dayjs().utc());

        return (
            <Row>
                 {dayjs().isBefore(dayjs(competition?.startDate )) && 
                    <Row  className='contador' >
                        <Col xs={50} sm={50} md={{ span: 24, offset:10}}>
                            <hr />{'El torneo empieza en'} <br />
                            <Row>
                                <Col xs={5} sm={5} md={{ span: 7}}>
                                    {dayjs(competition?.startDate ).diff(dayjs(),'days')}
                                </Col>
                                <Col  xs={9} sm={9} md={{ span: 8}}>
                                    {dayjs(competition?.startDate ).diff(dayjs(),'hours')%24}
                                </Col>
                                <Col xs={8} sm={8} md={{ span: 8}}>
                                    {dayjs(competition?.startDate ).diff(dayjs(),'minutes')%(60)}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={50} sm={50} md={{ span: 7}}>
                                {'Días'}
                                </Col>
                                <Col xs={8} sm={8} md={{ span: 7}}>
                                {'Horas'}
                                </Col>
                                <Col xs={10} sm={10} md={{ span: 10}}>
                                {'Minutos'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    }
            </Row>
        )
       

}




export default ContadorComponent;