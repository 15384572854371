import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    participants: [],
    loading: false,
    idMensaje: null,
    mensaje: null,
    error: null,
};

const URL_API_GET_PARTICIPANTS = '/api/participants';
const URL_API_UPDATE_PARTICIPANT = '/api/participants/update';


export const ParticipantSlice = createSlice({
    name: 'participantS',
    initialState,
    reducers: {
        setParticipants: (state,action) => {
            state.participants = action.payload;
        },
        setUpdateParticipant: (state,action) => {
            state.idMensaje = action.payload.idMessage;
            state.mensaje = action.payload.message;
        },
        setLoading: (state,action) => {
            state.loading = action.payload;
        },
        setError: (state,action) => {
            state.error = action.payload;
        },
        setCleanMensaje: (state,action) => {
            state.idMensaje = null;
            state.mensaje = null;
        },
        resetParticipant: () => {
            return initialState;
        }   
    }    
})

export const {setParticipants, setUpdateParticipant,setParticipant, setNewParticipant, updateParticipant,setLoading,setCleanMensaje,setError,resetParticipant} = ParticipantSlice.actions;

export default ParticipantSlice.reducer;

// ******************************************************************************
export const cleanMensaje = () => async (dispatch) => {
    dispatch(setCleanMensaje());
}

export const cleanError = () => async (dispatch) => {
    dispatch(setError(null));
}

// ******************************************************************************

export const obtenerParticipantes =  (params) =>  async (dispatch) => {
    dispatch(setLoading(true));
    var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_PARTICIPANTS,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : params
      };
    await axios(config)
    .then((response) => {
        dispatch(setParticipants(response.data.participantes));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}

export const actualizarParticipante =  (params) =>  async (dispatch,getState) => {
    dispatch(setLoading(true));

    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_UPDATE_PARTICIPANT,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': getState().usuarioStore.usuario?.token
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(setUpdateParticipant(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}
