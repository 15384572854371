import { useSelector } from 'react-redux';

const useIsAdmin = () => {  
  const usuario = useSelector(state => state.usuarioStore.usuario);

  if (!usuario || !usuario?.roles) {
    return false;
  }

  // Verificar si el usuario tiene el rol de 'admin'
  return usuario.roles.some(role => role.name === 'admin');
};

export default useIsAdmin;
