import React, { useEffect,useState} from 'react';
import { useSelector,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row,Col,Collapse,Modal,Button,Spin} from 'antd';
import './HeaderOpciones.css';
import { InfoCircleOutlined,EditOutlined, UpCircleOutlined} from '@ant-design/icons';
import * as ConstantesMundial from '../ConstantesMundial';
import ContentComponent from '../ContentComponent';


const { Panel } = Collapse;

export const HeaderOpciones = (props) => {
    
    const {txtButton,txtAdd,txtRules,showButton} = props;


    const dispatch = useDispatch();
    const navigate = useNavigate() // esto en lugar de history en esta version

    

    const [isModalOpen, setIsModalOpen] = useState(false);
    

    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };

    const irARegistro = () => {
        navigate('/registro')
    }
    useEffect(() => {

        
    }, []);


  return (
    <>
        <Spin spinning={false}> 
            <Row  className='contenedorOpciones' justify={'center'}>
                <Col xs={24} sm={24} md={{ span: 12}}>
                    <Button onClick={showModal} icon={<UpCircleOutlined />} className='btn-reglas'>
                        {txtButton}
                    </Button>
                </Col>
                  {showButton &&
                    <Col xs={24} sm={24} md={{ span: 12}}>
                    <Button onClick={() => irARegistro()} icon={<InfoCircleOutlined />} className='btn-reglas'>
                        {txtAdd}
                    </Button>
                </Col>
                }
            </Row>
            
            <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                okButtonProps={{ className: 'btn-reglas' }} cancelButtonProps={{ className: 'btn-reglas', hidden: true}}
                >
                <p className='txt-modal-info'>{txtRules}</p>
            </Modal>
                </Spin>
    </>
  )
  
}


export default HeaderOpciones;