import React, { useEffect,useState } from 'react';
import { useSelector,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PoweroffOutlined,DownOutlined, SettingOutlined ,UserOutlined } from '@ant-design/icons';
import { Button,Dropdown, Space,Avatar,Typography } from 'antd';
import './Navbar.css';


const { Text, Link } = Typography;


const UsuarioDropDown = (props) => {

    const usuario = useSelector(state => state.usuarioStore.usuario);

    const onClick = ({ key }) => {
       //console.log(key)
        if(key === '1'){
            props.logOut()
        }
      };


    const items = [
        {
          key: '1',
          label: (<>
          <PoweroffOutlined /> Cerrar sesion</>
           
          ),
        },
        {
          key: '2',
          label: (<>
            <SettingOutlined /> Configurar</>),
          disabled: true,
        }
      ];

    

  return (
    <> 
        <Dropdown menu={{ items,onClick }}>
            <a onClick={(e) => e.preventDefault()}>
                <Space size='large'>
                <Avatar icon={<UserOutlined />}  style={{backgroundColor:'#004346ff'}} />
                <Text strong style={{color:'#74b3ceff',wordBreak:'normal'}}>{usuario.userName}</Text>
                <DownOutlined />
                </Space>
            </a>
        </Dropdown>        
    </>
  )
}

export default UsuarioDropDown;