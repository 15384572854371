import React,  { useEffect}from 'react';
import { Button, Form, Input, Row,Col, Spin,notification,Typography,Alert} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import './Login.css'
import { useSelector,useDispatch} from 'react-redux';
import { resetUsuario,forgotPassword} from '../../redux/login/usuarioSlice'
import { MailOutlined } from '@ant-design/icons';
import WorkInProgress from '../Templates/WorkInProgress';
const { Title } = Typography;

const ForgotPass = () => {  
  const dispatch = useDispatch();

  const usuarioStore = useSelector(state => state.usuarioStore);

  useEffect(() => {
    //console.log('hola'); 
  },[])

  useEffect(() => {
    // console.log(usuarioStore);
    if(usuarioStore.idMensaje && usuarioStore?.mensaje){
      if(usuarioStore.idMensaje === 1){
        notification.success({
          className:'notification-custom',          
          description: usuarioStore.mensaje
        });      
      }
      else {
        notification.error({
          className:'notification-custom',
          message: '',
          description: usuarioStore.mensaje,
        });        
      }
      dispatch(resetUsuario());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usuarioStore.idMensaje])

  const onFinish = (values) => {
    console.log(values)
    dispatch(forgotPassword(values))
  };


  return (
    <Content className='mt-50'>
      <Spin spinning={usuarioStore?.loading}>
        <WorkInProgress />
        {false && <Row justify="center">
          <Col xs={22} sm={18} md={12} lg={8}>
            <div className="login-container">
              <Title level={2} className="login-title">Recuperar mi contraseña</Title>
              <Alert showIcon type="info"
                description='Ingresa tu correo electronico y si coincide con tu cuenta te enviaremos un enlace para que puedas crear una nueva contraseña. Si no te ha llegado en unos minutos, revisa la carpeta de los correos no deseados.'                
              />              
              <Form
                name="forgot"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                className="formulario-login mt-50"
              >
               <Form.Item label='Correo' name='email'  rules={[{ required: true,type: 'email' }]}>
                  <Input  placeholder={'Correo'} prefix={<MailOutlined />}/>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn-login" block >
                    Recuperar contraseña
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>}
      </Spin>
    </Content>
       
  )
}

export default ForgotPass