import React, { useEffect,useState} from 'react';
import { useSelector,useDispatch} from 'react-redux';
import {Row,Col,Image,Card  } from 'antd';
import { useNavigate } from 'react-router-dom';
import { obtenerCompeticiones,setCompetition } from '../../../redux/Mundial/competitionsSlice';


const HomeMundial = () => {

  const navigate = useNavigate() // esto en lugar de history en esta version
  const dispatch = useDispatch();

  const competitions = useSelector(state => state.competitionsStore.competitions);

  useEffect(() => {
    dispatch(obtenerCompeticiones());  
    return () => {
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

//   useEffect(() => {
//     // console.log(competitions);
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [competitions])

  const irAQuiniela = (comp) => {
    // console.log(comp);
    dispatch(setCompetition(comp));
    navigate('/mundial');
  };

  return(
    <>
      {/* <Row  style={{marginTop:'20px'}}>
        <Col xs={{span:10,offset:1}} sm={12} md={{ span: 6,offset:4}}>
          <Card
            title=""
            bordered={false}
            extra={<a href="#/mundial">
              <Image  src={'mundialuwu'} className='imgQT'
            width='100%' preview={false} alt="Mundial" onClick={() => navigate('/mundial')} /></a>}
            
          >
            <p className='textoC'> MUNDIAL 2</p>
          </Card>
        
        </Col>

        <Col xs={{span:10,offset:2}} sm={12} md={{ span:6,offset:4}}>
          <Card
            title=""
            bordered={false}
            extra={<a href="#/mundial">
              <Image  src={'https://a2.espncdn.com/combiner/i?img=%2Fi%2Fleaguelogos%2Fsoccer%2F500%2F83.png'} 
            height='100%' preview={false} alt="Mundial" onClick={() => navigate('/mundial')} /></a>}
           
          >
        <p className='textoC'>COPA AMERICA</p>
          </Card>
        
        </Col>
        
      </Row> */}

      <Row>        
        {competitions?.map( comp => (
            <Col xs={{span:10,offset:1}} sm={12} md={{ span: 6,offset:4}}>
                <Card
                    title={comp?.name}
                    bordered={false}  
                    headStyle={{textAlign:'center'}}      
                >
                    <Image  src={comp?.logo} onClick={() => irAQuiniela(comp)}  className='imgQT'
                    width='100%' preview={false} alt="Mundial" />
                </Card>
            </Col>
        ))}
      </Row>

      
    </>

    
    
    )
}

export default HomeMundial;