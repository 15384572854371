export const tokenTheme =  {
    colorPrimary: "#33bcb7",
    colorPrimaryHover:"#1C4841",
    defaultHoverBorderColor:"#fff",
    fontFamily: "Roboto, sans-serif",
};

export const compomentsTheme =  {
        Tabs: {
            colorBgContainer:"#222",
        },    
};

// "colorPrimaryHover":"#508991ff",
// "colorLink": "#09bc8aff",
// "colorLinkActive": "#333",
// "colorLinkHover": "#004346",
// "borderRadius": "10px",
// 
// "colorTextBase": "#fff",
// "colorText": "#fff",
// "colorBgTextHover":"#fff",
// "colorBgContainer": "#3f5d75",
// "colorBorder": "1px solid red",
// "_Dropdown_": "---------------------",
// "colorBgElevated":"#3f5d75"