import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    matches: [],
    totalMatches: null,
    match:{},
    loading: false,
    idMensaje: null,
    mensaje: null,
    error: null,
    realMatches: []
};

const URL_API_GET_MATCHES = '/api/matches';
const URL_API_GET_MATCHES_SCORES = '/api/matches/matchesScores';
const URL_API_UPDATE_MATCH = '/api/matches/update';



export const MatchSlice = createSlice({
    name: 'matchS',
    initialState,
    reducers: {
        setMatches: (state,action) => {
            state.matches = action.payload.matches;
            state.totalMatches = action.payload.count;
        },
        setRealMatches: (state,action) => {
            state.realMatches = action.payload.matches;
        },
        setMatch: (state,action) => {
            state.match = action.payload;
        },
        setNewMatch: (state,action) => {
            state.newMatch = action.payload;
        },
        setUpdateMatch: (state,action) => {
            state.idMensaje = action.payload.idMessage;
            state.mensaje = action.payload.message;
        },
        setLoading: (state,action) => {
            state.loading = action.payload;
        },
        setError: (state,action) => {
            state.error = action.payload;
        },
        setCleanMensaje: (state,action) => {
            state.idMensaje = null;
            state.mensaje = null;
        },
        resetMatch: () => {
            return initialState;
        }   
    }    
})

export const {setMatches, setRealMatches, setMatch, setNewMatch, setUpdateMatch,setLoading,setError,setCleanMensaje,resetMatch} = MatchSlice.actions;

export default MatchSlice.reducer;

// ******************************************************************************
export const cleanMensaje = () => async (dispatch) => {
    dispatch(setCleanMensaje());
}

export const cleanError = () => async (dispatch) => {
    dispatch(setError(null));
}
// ******************************************************************************

export const obtenerPartidos =  (data) =>  async (dispatch) => {
    dispatch(setLoading(true));
    let config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_MATCHES,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
      };
    await axios(config)
    .then((response) => {
        dispatch(setMatches(response.data.matches));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}


export const obtenerPartidosMarcador =  (params) =>  async (dispatch) => {
    dispatch(setLoading(true));

    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_MATCHES_SCORES,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(setMatches(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}

export const obtenerResultadosReales =  (params) =>  async (dispatch) => {
    dispatch(setLoading(true));
    
    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_MATCHES_SCORES,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(setRealMatches(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}

export const actualizarPartido =  (params) =>  async (dispatch,getState) => {
    dispatch(setLoading(true));

    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_UPDATE_MATCH,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': getState().usuarioStore.usuario?.token
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(setUpdateMatch(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}
