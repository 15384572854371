import React, { useEffect,useState,Fragment} from 'react';
import { useSelector,useDispatch} from 'react-redux';
import { Row,Col,Card, Table,Tooltip,Button, Avatar,Skeleton, Space, Typography, Image} from 'antd';
import { EditOutlined,SettingOutlined,LockFilled,MinusOutlined} from '@ant-design/icons';
import './TablasResultados.css';
import ConfigScoreModal from '../configuracion/ConfigScoreModal';
import {obtenerPartidosMarcador} from '../../../redux/Mundial/matchSlice';
import ConfigMatchModal from '../configuracion/ConfigMatchModal';
import useIsAdmin from '../../hooks/useIsAdmin';
import dayjs from 'dayjs'
import useBeginCompetition from '../../hooks/useBeginCompetition';
import { obtenerParticipantes } from '../../../redux/Mundial/participantsSlice';
import { SCORE_TYPE } from '../ConstantesMundial';

const { Text } = Typography;

export const TablaParticipante = (props) => {

    const dispatch = useDispatch();
    const isAdmin = useIsAdmin();
    const beginCompetition = useBeginCompetition();
    const {participante,marcadoresFinales} = props;

    const matchStore = useSelector(state => state.matchStore);
    const partidos = useSelector(state => state.matchStore.matches);
    const participantsStore = useSelector(state => state.participantsStore);
    const usuario = useSelector(state => state.usuarioStore.usuario);
    const faseActual = useSelector(state => state.competitionsStore.stage);

    const [isVisibleModScore, setIsVisibleModScore] = useState(false);
    const [isVisibleModMatch, setIsVisibleModMatch] = useState(false);
    const [recordActual, setRecordActual] = useState({});

    const defaultLogo =  <Image width={200} preview={false} src="https://www.gstatic.com/onebox/sports/logos/inverse-crest.svg"/>

    const columns = [
        Table.EXPAND_COLUMN,
        {
            title: 'Fecha',
            dataIndex: 'matchDate',
            width: '30px',
            align: 'center',
            // responsive: ['md'],
            key: 'matchDate',
            render: (text) => <>{dayjs(text).format('ddd DD MMM HH:mm')}</>
        },
        {
          title: faseActual==='FINALS' ? 'Etapa':'Grupo',
          dataIndex: faseActual==='FINALS' ?'stage':'group',
          width: '15px',
          align: 'center',
          key: 'username',
          responsive: ['md'],
          render: (text) => <b>{text}</b>,
        },
        {
            title: '',
            dataIndex: 'homeTeam',
            width: '30px',
            align: 'left',
            key: 'homeTeam',
            render: (text,record) => <> 
                <Row>
                    <Col xs={0} sm={0} md={24}>
                    <Avatar src={record.homeTeam?.logo ?? defaultLogo} />
                        {record.homeTeam?.name}
                    </Col>
                    <Col xs={20} sm={20} md={0} className='txt-12 extraData'>
                    <Avatar src={record.homeTeam?.logo ?? defaultLogo} />
                    </Col>
                    <Col xs={20} sm={20} md={0} className='txt-12 extraData'>
                    {record.homeTeam?.name}
                    </Col>
                    
                </Row></>
        },
        {
        title: '',
        dataIndex: 'scoreHome',
        width: '10px',
        align: 'center',
        key: 'scoreHome',
        render: (text,record) => <> {record.score?.home ? (puedeVerMarcador(record)  ?  record.score?.home : <LockFilled />)  : <MinusOutlined />}</>
        
        },
        {
        title: '',
        dataIndex: 'scoreAway',
        width: '10px',
        align: 'center',
        key: 'scoreAway',
        render: (text,record) => <> {record.score?.away ? (puedeVerMarcador(record)  ?  record.score?.away : <LockFilled />)  : <MinusOutlined />}</>

        },
        {
        title: '',
        dataIndex: 'awayTeam',
        width: '30px',
        align: 'right',
        key: 'awayTeam',
        render: (text,record) => <>             
            <Row>
            <Col xs={0} sm={0} md={24}>
            {record.awayTeam?.name}<Avatar src={record.awayTeam?.logo ?? defaultLogo} />
            </Col>
            <Col xs={20} sm={20} md={0} className='txt-12 extraData'>
            <Avatar src={record.awayTeam?.logo ?? defaultLogo} />
            </Col>
            <Col xs={20} sm={20} md={0} className='txt-12 extraData'>
            {record.awayTeam?.name}
            </Col>
            
        </Row></>
        },  
        {
            title: "Puntos",
            width: '20px',       
            align: 'center',
            hidden: marcadoresFinales,
            render: (text,record) => <> {record.score !== undefined ? record.score?.points : 'No data'}</>
        },{
            title: '',
            width: '25px',       
            align: 'center',
            hidden: !(usuario.isLogged && (usuario?.userName === participante?.username || isAdmin)),
            render: (value, record,index) =>
            <>  <Space size={'small'}>
                { ((usuario?.userName === participante?.username) || isAdmin) &&
                    <Tooltip title={(isAdmin || !beginCompetition || beginMatch(record?.matchDate))  ? ((record?.awayTeam && record?.homeTeam) || isAdmin ? 'Editar marcador':'Por definirse'):'Ya no se puede editar'}>
                        <Button
                            size={"small"}
                            onClick={() => showModalScore(record,index)}
                            className="btn-config"
                            type="primary"
                            // style={{ marginRight: "5px" }}
                            shape="circle"
                            disabled={!((isAdmin || !beginCompetition || beginMatch(record?.matchDate)) && ((record?.awayTeam && record?.homeTeam) || isAdmin)) }
                            icon={<EditOutlined />}                        
                        />
                    </Tooltip>                
                }
                {isAdmin && marcadoresFinales &&                
                    <Tooltip title={'Editar partido'}> 
                        <Button
                            size={"small"}
                            hidden={!marcadoresFinales}
                            onClick={() => showModalMatch(record,index)}
                            className="btn-config"
                            type="primary"
                            // style={{ marginRight: "5px" }}
                            shape="circle"
                            icon={<SettingOutlined />}                            
                        />
                    </Tooltip>
                }
                </Space>
            </>
        }
    ].filter(item => !item.hidden);

    const beginMatch = (date) => {
        // console.log(date);
        //2024-07-5 19:59
        return dayjs().isBefore(dayjs(date));
    }

    const puedeVerMarcador = (record) => {
        return (beginCompetition && !beginMatch(record?.matchDate)) || usuario?.userName === participante?.username || isAdmin
    }

    const showModalScore = (record,index) => {
        setIsVisibleModScore(true);
        setRecordActual(record)
    }

    const cerrarModalScore = () => {
        setIsVisibleModScore(false);
    }

    const showModalMatch = (record,index) => {
         setIsVisibleModMatch(true);
         setRecordActual(record)
     }
 
     const cerrarModalMatch = () => {
         setIsVisibleModMatch(false);
     }
     
    useEffect(() => {         
        let data = JSON.stringify({
            usuario: participante?.username,
            grupo: "",
            stage: faseActual,
            scoreType: participante ? SCORE_TYPE.user : SCORE_TYPE.final
        });
        dispatch(obtenerPartidosMarcador(data)); 
        dispatch(obtenerParticipantes({stage: faseActual})); //dispatch manda a llamar al back o setean datos en el store
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faseActual]);

    useEffect(() => {
        // console.log('Entrando-> '+participante?.username);
        return () => {
            // console.log('Saliendo-> '+participante?.username);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


  return (
    <>
        <Skeleton loading={participantsStore.loading || matchStore?.loading }>              
            {
                !marcadoresFinales && <Row justify='center'>
                <Col xs={{ span: 10 ,offset:1}} sm={{ span: 10 ,offset:1}}  md={{ span: 6}} className='cardsPuntos'>
                    <Card title="Participante" bordered={false}>                        
                        <Text   style={{width: 200,}} 
                                ellipsis={ {tooltip: participante?.username,}}
                                className='txt-22'
                        >
                            {participante?.username}
                        </Text>
                    </Card>
                </Col>
                <Col xs={{ span: 10 ,offset:1}} sm={{ span: 10 ,offset:1}}  md={{ span: 6 ,offset:2}}  className='cardsPuntos'>
                    <Card title="Total de puntos" bordered={false} >
                        {participante?.sumaPuntos}
                    </Card>
                </Col>
                
            </Row>
            }
            
            <Row style={{marginTop:'20px'}} justify={'center'}>
                <Col    xs={{ span:22, offset: 1}} sm={{ span: 22, offset: 1}} 
                        md={{ span: marcadoresFinales? 16: 22, offset: 1}}>
                    <Table className='tablaPuntos' 
                        rowKey="_id"
                        columns={columns}
                        pagination={false}
                        bordered={false}
                        // scroll={{ y: 600, x: true}}      
                        size="small"                 
                        expandable={marcadoresFinales ? {} :{
                        expandedRowRender: (record,index) => (
                            <>
                                <Row  style={{  margin: 0 }} >
                                    <Col xs={22} sm={22} md={{ span:20 }}>
                                        <Row>
                                            <Col xs={24} sm={24} md={{ span:12,offset:4 }}>
                                                <Text strong>{'Fecha: '}</Text> {dayjs(record.matchDate).format('dddd DD MMMM HH:mm')}
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col xs={24} sm={24} md={{ span:12,offset:4 }}>
                                                <Text strong>{'Grupo: '}</Text> {record.group}
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col xs={24} sm={24} md={{ span:12,offset:4 }}>
                                                <Text strong>{'Fase: '}</Text> {record.stage}
                                            </Col>                                            
                                        </Row>                                       
                                    </Col>                                                                                                             
                                </Row>
                            </>
                        )
                        }}
                        dataSource={partidos} 
                        expandIconAsCell={false}
                    />
                </Col>
            </Row>
        </Skeleton>
        
        <ConfigScoreModal isVisible={isVisibleModScore} cerrarModal={cerrarModalScore} record={recordActual} participante={participante} marcadoresFinales={marcadoresFinales} defaultLogo={defaultLogo}/>
        <ConfigMatchModal isVisible={isVisibleModMatch} cerrarModal={cerrarModalMatch} record={recordActual} marcadoresFinales={marcadoresFinales}  defaultLogo={defaultLogo} />
    </>
  )
}


export default TablaParticipante;