import React, { useEffect,useState,Fragment} from 'react';
import { useSelector,useDispatch} from 'react-redux';
import { Row,Col,Card,Table,Tooltip,Button} from 'antd';
import { ExclamationOutlined , CheckOutlined,SearchOutlined,CloseOutlined,EditOutlined,EyeInvisibleOutlined ,TrophyOutlined ,NumberOutlined  } from '@ant-design/icons';
// import dayjs from 'dayjs';
// import 'dayjs/locale/es';
import './TablasResultados.css';
import { obtenerPartidosMarcador, obtenerResultadosReales } from '../../../redux/Mundial/matchSlice';
import { obtenerParticipantes } from '../../../redux/Mundial/participantsSlice';
import EditPartipantModal from '../configuracion/EditParticipantModal';
import useIsAdmin from '../../hooks/useIsAdmin';
import useBeginCompetition from '../../hooks/useBeginCompetition';

export const TablaPuntaje = (props) => {
    
    const dispatch = useDispatch();
    const isAdmin = useIsAdmin();
    const beginCompetition = useBeginCompetition();

    const participantsStore = useSelector(state => state.participantsStore);
    const usuario = useSelector(state => state.usuarioStore.usuario);
    const competition = useSelector(state => state.competitionsStore.competition);
    const faseActual = useSelector(state => state.competitionsStore.stage);
 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [participanteActual, setParticipanteActual] = useState(null);
    
    const columns = [
        {
            title: 'Lugar',
            dataIndex: 'placement',
            width: '10%',
            align: 'center',
            key: 'placement',
            render: (value, record, index) =>
            <>
                {index+1}
            </>
          },
        {
          title: 'Participante',
          dataIndex: 'name',
          width: '40%',
          align: 'center',
          key: 'username',
          render: (text) => <b>{text}</b>,
        },
        {
            title: 'Estatus',
            dataIndex: 'estatus',
            width: '10%',
            align: 'center',
            key: 'estatus',
            render: (value, record, index) =>
            <>
                {
                    faseActual === 'FINALS' ? 
                        <Tooltip title={
                            record.estatus === '0' ? 'Pendiente: Pago y marcadores'
                                :(record.estatus === '1' ? 'Completo'
                                    : (record.estatus === '2' ? 'Pendiente: Pago'
                                    : (record.estatus === '-1' ? 'No participando'
                                    : 'Pendiente: Marcadores'  ) ))
                        }>
                            {record.estatus === '0' ? <Button  className='btn-danger' type="text" size={"small"}  shape="circle" icon={<CloseOutlined/>}/> 
                                :record.estatus === '1' ? <Button className='btn-success' type="text" size={"small"}   shape="circle" icon={<CheckOutlined />}/> 
                                    : record.estatus === '-1' ? <Button className='btn-danger' type="text" size={"small"}   shape="circle" icon={<EyeInvisibleOutlined   />}/> 
                                    : <Button className='btn-warning' type="text" size={"small"}   shape="circle" icon={<ExclamationOutlined  />}/> 
                            }
                        </Tooltip>
                    :
                        <Tooltip title={
                            record.estatus === '0' ? 'Pendiente: Pago y marcadores'
                                :(record.estatus === '1' ? 'Completo'
                                    : (record.estatus === '2' ? 'Pendiente: Pago'
                                    : (record.estatus === '-1' ? 'No participando'
                                    : 'Pendiente: Marcadores'  ) ))
                        }>
                            {record.estatus === '0' ? <Button  className='btn-danger' type="text" size={"small"}  shape="circle" icon={<CloseOutlined/>}/> 
                                :record.estatus === '1' ? <Button className='btn-success' type="text" size={"small"}   shape="circle" icon={<CheckOutlined />}/> 
                                    : record.estatus === '-1' ? <Button className='btn-danger' type="text" size={"small"}   shape="circle" icon={<EyeInvisibleOutlined   />}/> 
                                    : <Button className='btn-warning' type="text" size={"small"}   shape="circle" icon={<ExclamationOutlined  />}/> 
                            }
                        </Tooltip>
                }
                
                
            </>
          },
        {
          title: 'Puntos',
          dataIndex: 'sumaPuntos',
          width: '20%',
          align: 'center',
          key: 'points',
        },
        {
            title: "Acciones",
            width: "20%",            
            align: 'center',            
            render: (value, record,index) =>
            <>            
                <Tooltip title={ beginCompetition || usuario?.userName === record?.username ? 'Consultar' : 'Aun no se puede consultar'}>
                    <Button
                        size={"small"}
                        onClick={() => cambioTab(record,index)}                        
                        disabled={!isAdmin && !beginCompetition && usuario?.userName !== record?.username}
                        className="btn-acciones"
                        type="primary"
                        style={{ marginRight: "5px" }}
                        shape="circle"
                        icon={<SearchOutlined />}
                    />
                </Tooltip>
                {isAdmin && <Tooltip title={ 'Editar participante'}>
                    <Button
                        size={"small"}
                        onClick={() => showModal(record,index)}
                        className="btn-acciones"
                        type="primary"                        
                        style={{ marginRight: "5px" }}
                        shape="circle"
                        icon={<EditOutlined />}
                    />
                </Tooltip>}
            </>
        },
      ];

    const cambioTab = (record,index ) => {
        //console.log(record._id)
        const partSelect = participantsStore.participants.find(p => p._id === record._id);
        // console.log(partSelect)
        let data = JSON.stringify({
            usuario: partSelect.username,
            grupo: "",
            stage:faseActual,
            scoreType: "USER"
        });
        dispatch(obtenerPartidosMarcador(data)); 
        setParticipanteActual(partSelect);
        
        props.cambiarTab(partSelect?._id)
    }

    const showModal = (record,index) => {
        setIsModalOpen(true);
        setParticipanteActual(record);
    };

    const cerrarModal = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        
    }, []);

     useEffect ( () => {
        dispatch(obtenerParticipantes ({stage: faseActual})); 
        
     }, [faseActual]);

  return (
    <>  
       

        <Row justify='center'>
            <Col xs={{ span: 10 ,offset:1}} sm={{ span: 10 ,offset:1}}  md={{ span: 6}} className='cardsPuntos'>
                <Card title={<><NumberOutlined /> {"Participantes"} </>} bordered={false}>
                    {participantsStore.participants?.filter(item => item.estatus !== '-1').length}
                </Card>
            </Col>
            <Col xs={{ span: 10 ,offset:1}} sm={{ span: 10 ,offset:1}}  md={{ span: 6 ,offset:2}}  className='cardsPuntos'>
                <Card title={<><TrophyOutlined /> {"Premio"} </>}bordered={false}>
                    {'$ '+participantsStore.participants?.filter(item => item.estatus !== '-1').length * 100}                
                </Card>
            </Col>
            
        </Row>
        <Row justify='center' className='mt-20'>
            <Col xs={{ span: 22}} sm={{ span: 22}} md={{ span: 20}}>
                <Table className='tablaPuntos' 
                    columns={columns} 
                    size="middle" 
                    dataSource={isAdmin ? participantsStore.participants : participantsStore.participants?.filter(item => item.estatus !== '-1') } 
                    pagination={false}
                    bordered={false}
                />
            </Col>
        </Row>

        <EditPartipantModal isVisible={isModalOpen} cerrarModal={cerrarModal} participante={participanteActual} />
    </>
  )
}


export default TablaPuntaje;