import React from 'react';
import { Row, Col, Typography, Alert } from 'antd';
import { HourglassOutlined } from '@ant-design/icons';
import './WorkInProgress.css';

const { Title, Text } = Typography;

const WorkInProgress = () => {
  return (
    <Row justify="center" align="middle" className="wip-container">
      <Col>
        <div className="wip-content">
          <HourglassOutlined className="wip-icon" />
          <Title level={2}>Sección en Desarrollo</Title>
          <Text>Estamos trabajando arduamente en esta sección. ¡Gracias por tu paciencia!</Text>
          <Alert message="Próximamente" type="info" showIcon style={{ marginTop: 16 }} />
        </div>
      </Col>
    </Row>
  );
};

export default WorkInProgress;
