import React, { useEffect, useState } from "react";
import { Row,Col,Form,notification,Modal,Avatar,Button,Spin,Select} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {obtenerPartidosMarcador,actualizarPartido,cleanError,cleanMensaje} from '../../../redux/Mundial/matchSlice';
import {obtenerEquipos} from '../../../redux/Mundial/teamSlice';
import moment from 'moment';
import 'moment/locale/es';
import { SCORE_TYPE } from "../ConstantesMundial";


const ConfigMatchModal = (props) => {
    
    const { isVisible,record,marcadoresFinales,defaultLogo} = props;

    const dispatch = useDispatch();

    const teamStore = useSelector((state) => state.teamStore);
    const matchStore = useSelector((state) => state.matchStore);
    const faseActual = useSelector(state => state.competitionsStore.stage);
    

    const [formMatch] = Form.useForm();
    const [teamLocal, setTeamLocal] = useState(null);
    const [teamVisita, setTeamVisita] = useState(null);

    useEffect(() => {
        dispatch(cleanError());
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(isVisible){
            dispatch(obtenerEquipos());
            setTeamLocal(record?.homeTeam)
            setTeamVisita(record?.awayTeam)
            formMatch.setFieldsValue({
                'local': record?.homeTeam?._id,
                'visita':record?.awayTeam?._id,
            });
        }
    // eslint-disable-next-line
    }, [isVisible]);

    useEffect(() => {
        if(matchStore.idMensaje){
            if(matchStore.idMensaje === 1){
                notification.success({
                    className:'notification-custom',
                    message: matchStore.mensaje
                  });
            }
            else {
                notification.error({
                    className:'notification-custom',
                    message: matchStore.mensaje
                  });
            } 
            dispatch(cleanMensaje());
            let data = JSON.stringify({
                usuario:"",
                grupo: "",
                stage:faseActual,
                scoreType: marcadoresFinales ? SCORE_TYPE.final : SCORE_TYPE.user
            });
           //console.log(data)
            dispatch(obtenerPartidosMarcador(data)); 
            onCancel();
            
        }
    // eslint-disable-next-line
    }, [matchStore.idMensaje]);

    useEffect(() => {
         if(matchStore.error){
            notification.error({
                className:'notification-custom',
                message: matchStore.error.message
              });
             dispatch(cleanError());
             onCancel();
             
         }
     // eslint-disable-next-line
     }, [matchStore.error]);

    //Funciones
    const onCancel = () => {
        props.cerrarModal({confirmar:false});
    }
    const guardar = () => {        
        let formInvalid = formMatch.getFieldsError().some((item) => item.errors.length > 0);
        if(!formInvalid){
            if(formMatch.getFieldsValue().local === formMatch.getFieldsValue().visita){
                notification.error({
                    className:'notification-custom',
                    message: 'No se puede elegir el mismo equipo'
                });
            }
            else {
                let data = JSON.stringify({
                    _id: record._id,
                    homeTeam:formMatch.getFieldsValue().local,
                    awayTeam: formMatch.getFieldsValue().visita
                });
                dispatch(actualizarPartido(data));                        
            }
            
        }else{
            formMatch.validateFields();
        }
    }
    const onChangeTeamL = (value) => {
       let teamSel = teamStore.teams.find(t => t._id === value);
       setTeamLocal(teamSel)
    };
    const onChangeTeamV = (value) => {
        let teamSel = teamStore.teams.find(t => t._id === value);
        setTeamVisita(teamSel)
    };

    return (
        <Modal
            title={'Configurar Partido'}
            open={isVisible} width={550} 
            destroyOnClose={true}
            onCancel={onCancel}
            footer={null}
            closable={true}
            maskClosable={false} >
            <Spin spinning={matchStore.loading}>
            <Row  justify="center" className="mod-score" style={{  paddingBottom: '25px',textAlign:'center' }} > 
                                    <Col xs={24} sm={24} md={{ span:24 }}>
                                        <Row style={{textAlign:'center'}}>
                                            <Col xs={8} sm={8} md={{ span:8 }}>
                                                {moment(record.matchDate).format('dd, DD-MMM HH:mm')}
                                            </Col>
                                            <Col xs={8} sm={8} md={{ span:8 }}>
                                                {record.group}
                                            </Col>
                                            <Col xs={8} sm={8} md={{ span:8 }}>
                                                {record.stage }
                                            </Col> 
                                        </Row>
                                    </Col>
                                                                                                                                                   
                                </Row>
                <Form
                    form={formMatch}
                    layout="vertical"
                    name="formMatch"
                    autoComplete="off"
                    onFinish={guardar}
                >
                    <Row justify="center" className="mod-score">
                        <Col xs={11} sm={11} md={{ span:11 }} style={{textAlign:'center'}}>
                            <Row>
                                <Col xs={24} sm={24} md={{ span:6 }} style={{textAlign:'center'}}>
                                    <Avatar src={teamLocal?.logo ?? defaultLogo } />
                                </Col>
                                <Col xs={24} sm={24} md={{ span:18 }} style={{textAlign:'center'}}>
                            
                                    <Form.Item name='local' rules={[{ required: true, message: 'Requerido' }]}>                                    
                                        <Select placeholder="Seleccione" showSearch  allowClear                          
                                            onChange={onChangeTeamL}   
                                            optionFilterProp="children" 
                                            optionLabelProp="label"                          
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                                                }
                                            >
                                            {teamStore.teams !== undefined && teamStore.teams.map(item=>{
                                                return <Select.Option key={item._id} value={item._id} ids={item} label={item.shortName} ><Avatar src={item?.logo ?? defaultLogo} />{item.shortName}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span:11,offset:2 }} sm={{ span:11,offset:2 }} md={{ span:11,offset:2 }} style={{textAlign:'center'}}>
                            <Row>                                
                                <Col xs={24} sm={24} md={{ span:18 }} style={{textAlign:'center'}}>
                            
                                    <Form.Item name='visita' rules={[{ required: true, message: 'Requerido' }]}>                                    
                                        <Select placeholder="Seleccione" showSearch  allowClear                          
                                            onChange={onChangeTeamV}   
                                            optionFilterProp="children" 
                                            optionLabelProp="label"                          
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                                                }
                                            >
                                            {teamStore.teams !== undefined && teamStore.teams.map(item=>{
                                                return <Select.Option key={item._id} value={item._id} ids={item} label={item.shortName} ><Avatar src={item?.logo ?? defaultLogo} />{item.shortName}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={{ span:6 }} style={{textAlign:'center'}}>
                                    <Avatar src={teamVisita?.logo ?? defaultLogo} />
                                </Col>
                            </Row>
                        </Col>

                       

                        
                        
                    </Row>
                    <Row justify="center">
                        <Col span={24} style={{textAlign: "center"}}>
                            <Button 
                                type="primary" htmlType="submit"
                                className="ant-btn ant-btn-primary"
                            >Guardar</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}
export default ConfigMatchModal;