import {configureStore} from  '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import taskReducer from './tasks/taskSlice';
import productReducer from './products/productsSilce'
import usuarioReducer from './login/usuarioSlice';
import teamReducer from './Mundial/teamSlice';
import matchReducer from './Mundial/matchSlice';
import participantsSlice from './Mundial/participantsSlice';
import scoreSlice from './Mundial/scoreSlice';
import competitionsSlice from './Mundial/competitionsSlice';

const persistConfig = {
    key: 'root',
    storage,
};

// Combina todos los reducers en un rootReducer
const rootReducer = combineReducers({
        taskStore : taskReducer,
        productStore: productReducer,
        usuarioStore: usuarioReducer,
        teamStore: teamReducer,
        matchStore: matchReducer,
        participantsStore : participantsSlice,
        scoreStore : scoreSlice,
        competitionsStore : competitionsSlice       
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configura el store utilizando persistedReducer
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }),
});

export const persistor = persistStore(store);
export default store;