import React from 'react'
import { Outlet } from 'react-router-dom'

function Welcome() {
  return (
    <div>Welcome

        <br /><br /><br />
        <Outlet /> {/*para sub llamadas de componentes dentro de otro route*/}
    </div>
  )
}

export default Welcome