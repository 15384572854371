import React,  { useEffect}from 'react';
import { Button, Form, Input, Row,Col, Spin,notification,Typography,Alert} from 'antd';
import { useNavigate,useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import './Login.css'
import { useSelector,useDispatch} from 'react-redux';
import {login, resetUsuario,resetPassword} from '../../redux/login/usuarioSlice'
import { UserOutlined,KeyOutlined } from '@ant-design/icons';
import WorkInProgress from '../Templates/WorkInProgress';
const { Title } = Typography;

const ForgotPass = () => {

  const navigate = useNavigate() // esto en lugar de history en esta version
  const dispatch = useDispatch();
  const { token } = useParams();

  const usuarioStore = useSelector(state => state.usuarioStore);

  useEffect(() => {
    //console.log('hola');
    console.log('Token:', token); 
  },[])

  useEffect(() => {
    // console.log(usuarioStore);
    if(usuarioStore.idMensaje && usuarioStore?.mensaje){
      if(usuarioStore.idMensaje === 1){
        notification.success({
          className:'notification-custom',          
          description: usuarioStore.mensaje
        });      
        navigate('/login')
      }
      else {
        notification.error({
          className:'notification-custom',
          message: '',
          description: usuarioStore.mensaje,
        });
      }
      dispatch(resetUsuario());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usuarioStore.idMensaje])


  const onFinish = (values) => {
    console.log(values)
    dispatch(resetPassword(values,token))
  };

  return (
    <Content className='mt-50'>
      <Spin spinning={usuarioStore?.loading}>
        <WorkInProgress />
        {false && <Row justify="center">
          <Col xs={22} sm={18} md={12} lg={8}>
            <div className="login-container">
              <Title level={2} className="login-title">Recuperar mi contraseña</Title>
              <Alert showIcon type="info"
                description='Ingresa tu nueva contraseña.'                
              />              
              <Form
                name="reset"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                className="formulario-login mt-50"
              >
               <Form.Item label="Contraseña" name="password"
                  rules={[{ required: true, message: 'Ingresa contraseña' }]}
                >
                <Input.Password allowClear prefix={<KeyOutlined />} />
              </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn-login" block >
                    Guardar nueva contraseña
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>}
      </Spin>
    </Content>
       
  )
}

export default ForgotPass