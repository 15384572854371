import { Route,Routes } from 'react-router-dom';

import Home from '../components/home/Home';
import NotFound from '../components/home/NotFound';
import Login from '../components/Login/Login';
import Registro from '../components/Login/Registro';
import Welcome from '../components/home/Welcome';
import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import {setUsuario} from '../redux/login/usuarioSlice';
import Layout from '../components/home/Layout';
import RequireAuth from '../components/Login/RequireAuth';
import Unauthorized from '../components/home/Unauthorized';
import MundialHome from '../components/Mundial/ContentComponent.js';
import Forgot from '../components/Login/ForgotPass.js';
import ResetPass from '../components/Login/ResetPass';

function AppRouter() {

  const dispatch = useDispatch();

  const ROLES = {
    'User': 'user',
    'Mod': 'mod',
    'Admin': 'admin',
    'Mundial': 'mund'
  }

  useEffect(() => {
   //console.log('AppRouter')
    const usuarioJSON = window.localStorage.getItem('usuarioNks');
    if(usuarioJSON){
      dispatch(setUsuario(JSON.parse(usuarioJSON)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
        <Routes>
          
          
            <Route path="/" element={<Layout />} >
              {/*Public routes*/}
              <Route path="" element={ <Home />} />
              <Route path="login" element={ <Login />} />
              <Route path="forgot" element={ <Forgot />} />
              <Route path="mundial" element={<MundialHome />} />
              {/* <Route path="forgot" element={ <ForgotPassword />} />*/}
              <Route path="reset-password/:token" element={ <ResetPass />} /> 
              
              <Route path="unauthorized" element={<Unauthorized />} />

              {/*Protected routes*/}
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin,ROLES.Mod,ROLES.User]}/>} >                      
                <Route path="mundial" element={<MundialHome />} />
              </Route>

              <Route /*element={<RequireAuth allowedRoles={[ROLES.Admin]}/>} */>
                <Route path="registro" element={<Registro />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.User]}/>} >                      
                  <Route path="/about/*" element={<Welcome />}></Route>
              </Route>

              {/*cath all*/}
              <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    </>
  )
}

export default AppRouter