import React, { useEffect,useState} from 'react';
import { useSelector,useDispatch} from 'react-redux';
import {Row,Col,Tabs,Spin} from 'antd';
import {obtenerPartidosMarcador,obtenerResultadosReales} from '../../../redux/Mundial/matchSlice';
import {obtenerParticipantes} from '../../../redux/Mundial/participantsSlice';
import './TablasResultados.css';
import dayjs, { isDate } from 'dayjs';
import 'dayjs/locale/es';
import {TablaPuntaje} from './TablaPuntaje';
import {TablaParticipante} from './TablaParticipante';
import {setDataStage} from '../../../redux/Mundial/competitionsSlice';
import {ContadorComponent} from '../Templates/ContadorComponent';
import GameFase from '../Templates/GameFase';
import useIsAdmin from '../../hooks/useIsAdmin';
import useBeginCompetition from '../../hooks/useBeginCompetition';

export const TablasResultados = (props) => {

    const dispatch = useDispatch();
    const isAdmin = useIsAdmin();
    const beginCompetition = useBeginCompetition();

    const matchStore = useSelector(state => state.matchStore); //Se trae todos los datos
    const competition = useSelector(state => state.competitionsStore.competition); //Solo se trae la variable competition
    const participantsStore = useSelector(state => state.participantsStore);
    const faseActual = useSelector(state => state.competitionsStore.stage);
    const usuario = useSelector(state => state.usuarioStore.usuario);

    const [activeKey, setActiveKey] = useState('1');    
    const [participanteActual,setParticipanteActual] = useState(null);    
    
    const cambioTab = (data) => {
      setActiveKey(data);
      const partSelect = participantsStore.participants.find(p => p._id === data);
      setParticipanteActual(partSelect);
    }

    const defaultItems = [
        {
          key: '1',
          label: 'Puntajes',
          children:
            <TablaPuntaje cambiarTab={cambioTab} />,
        },
        {
          key: '2',
          label: 'Marcadores oficiales',
          // disabled: !isAdmin,
          children:<TablaParticipante participante={activeKey === '2'? null:participanteActual} marcadoresFinales={activeKey === '2'}/>          
        }
    ];
    const participantsItems = participantsStore?.participants?.map((p, index) => {        
        return  {
            key: p._id,
            label: p.username,
            disabled: !beginCompetition && usuario?.userName !== p?.username,
            children: <TablaParticipante participante={activeKey === '2'? null:participanteActual} marcadoresFinales={activeKey === '2'}/>,
            record: p
        };
    });

    const finalItems = [...defaultItems,...participantsItems];

    

    useEffect(() => { //se ejecuta siempre que se carga el componente cuanoo elarray del final esta vacio
        dispatch(setDataStage (dayjs().isBefore(dayjs('2024-07-03 00:10')) ? 'GROUP_STAGE':'FINALS' )).then(() => {          
        dispatch(obtenerParticipantes({stage: faseActual}));
        });        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    useEffect(() => { //Se va a ejecutar cuando detecte un cambio en la variable especificada en el array
        let participantSel = participantsStore.participants.find(p => p._id === participanteActual?._id);
        console.log(participantSel);
        setParticipanteActual(participantSel); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantsStore.participants]); 
    
    //CONTROL DE CAMBIO DE TABS
    const onChange = (key) => {
        // console.log(key)
        const partSelect = participantsStore.participants.find(p => p._id === key);        
        setParticipanteActual(partSelect);
        //si partSelect no tiene info es que son las dos primeras tab
        if(key === '1'){
          dispatch(obtenerParticipantes( { stage: faseActual }));
        }
        else{
          let data = {
            usuario: partSelect?.username ?? '',
            grupo: "",
            stage: faseActual,
            scoreType: partSelect ? "USER" :"FINAL"
          }; 
          // console.log(data)
          dispatch(obtenerPartidosMarcador(data)); 
        }
        setActiveKey(key);
    };

  return (
    <>        
        <Spin spinning={matchStore?.loading || participantsStore?.loading}>                    
            <ContadorComponent/>            
            <GameFase />
            <Row  className='contenidoTablas mt-50'>        
                <Col xs={24} sm={24} md={{ span: 20, offset:2}}>
                    <Tabs defaultActiveKey="1" 
                        activeKey={activeKey}
                        items={finalItems} 
                        onChange={onChange} 
                        // type="card"
                        size={'large'}
                        destroyInactiveTabPane={true}
                    />
                </Col>  
            </Row>          
        </Spin>
    </>
  )
}


export default TablasResultados;