import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    productos: [],
    mensaje: null
};

//export const getProducts = createAsyncThunk('/productos/getProductos',asyn)

export const ProductsSlice = createSlice({
    name: 'productsS',
    initialState,
    reducers: {
        setProductsList: (state,action) => {
            state.productos = action.payload;
        }
        

    }
})

export const {setProductsList} = ProductsSlice.actions

export default ProductsSlice.reducer


export const getProductos =  () =>  (dispatch) => {
        var config = {
            method: 'get',
            url: 'http://localhost:8000/api/products',
            headers: { 
                'Content-Type': 'application/json'
              },
          };
        axios(config)
        .then((response) => {
            //console.log(response);
            dispatch(setProductsList(response.data));
        })
        .catch ((error) => console.log(error))
}