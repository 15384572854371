import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notification } from 'antd';

const initialState = {
    usuario: {},
    newUsuario: {},
    loading: false,
    mensaje: null,
    idMensaje: null
};

const URL_API_GET_LOGIN = '/api/auth/login';
const URL_API_GET_REGISTRAR = '/api/auth/signup';
const URL_API_FORGOT_PASS = '/api/auth/forgotPassword';
const URL_API_RESET_PASS = '/api/auth/resetPassword';

export const UsuarioSlice = createSlice({
    name: 'usuarioS',
    initialState,
    reducers: {
        setUsuario: (state,action) => {
            state.usuario = action.payload;
        },
        setNewUsuario: (state,action) => {
            state.newUsuario = action.payload;
        },
        updateUsuario: (state,action) => {
            return {...state, ...action.payload}
        },
        setLoading: (state,action) => {
            state.loading = action.payload;
        },
        setMensaje: (state,action) => {
            state.idMensaje = action.payload.idMessage;
            state.mensaje = action.payload.message;
        },
        resetUsuario: () => {
            return initialState;
        }
        

    }
})

export const {setUsuario,setNewUsuario,updateUsuario,resetUsuario,setLoading,setMensaje} = UsuarioSlice.actions

export default UsuarioSlice.reducer

// ******************************************************************************


export const login =  (data) =>  async (dispatch) => {
    dispatch(setLoading(true));
    var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_LOGIN,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
      };
    await axios(config)
    .then((response) => {
        dispatch(setUsuario(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setLoading(false));
        dispatch(setUsuario(error.response.data));
        
    })
}

export const registrarUsuario =  (data) =>  async (dispatch) => {
    dispatch(setLoading(true));
    var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_REGISTRAR,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
      };
    await axios(config)
    .then((response) => {
        dispatch(setNewUsuario(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error);        
        notification.error({
            className:'notification-custom',
            message: 'Ocurrio un error',
            description: error?.response?.data?.message
          });
        dispatch(setLoading(false));
    })
}


export const forgotPassword =  (data) =>  async (dispatch) => {
    dispatch(setLoading(true));
    var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_FORGOT_PASS,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
      };
    await axios(config)
    .then((response) => {
        dispatch(setMensaje(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setMensaje(error.response.data));
        dispatch(setLoading(false));
    })
}
export const resetPassword =  (data,token) =>  async (dispatch) => {
    dispatch(setLoading(true));
    var config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_RESET_PASS,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': token
        },
        data : data
      };
    await axios(config)
    .then((response) => {
        dispatch(setMensaje(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setMensaje(error.response.data));
        dispatch(setLoading(false));
    })
}