import React, { useEffect,useState } from 'react';
import logo from '../../img/Nks_sky_blue.png';
import {Nav, Bars, NavMenu,NavLink,NavBtn,NavBtnLink, NavbarContainer, NavWrapper,Menu,MenuItem,
    MenuItemLink, MobileIcon,} from './NavbarElements';
import { FaBars,FaHome, FaTimes, FaUserAlt,} from "react-icons/fa";
  import { IconContext } from "react-icons";
import './Navbar.css';
import { useSelector,useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PoweroffOutlined,DownOutlined, SmileOutlined,UserOutlined } from '@ant-design/icons';
import { Button,Typography, Space,Avatar } from 'antd';
import {resetUsuario} from '../../redux/login/usuarioSlice';
import {setUsuario} from '../../redux/login/usuarioSlice';
import UsuarioDropDown from './UsuarioDropDown';

const { Text } = Typography;

const Navbar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate() // esto en lugar de history en esta version

    const usuario = useSelector(state => state.usuarioStore.usuario);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        
        const usuarioJSON = window.localStorage.getItem('usuarioNks');
        if(usuarioJSON){
          dispatch(setUsuario(JSON.parse(usuarioJSON)));
        }
    
      },[])

    const logOut = () => {
       //console.log('Cerrando')
        window.localStorage.removeItem('usuarioNks');
        dispatch(resetUsuario());
        setShowMobileMenu(false);
        navigate('/')

    }
    

  return (
    <> 
        <NavbarContainer>
            <NavWrapper>
                <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
                    <NavLink to="/">
                        <img src={logo} className="logo" alt="logo" />   
                    </NavLink>

                    <MobileIcon onClick={() => setShowMobileMenu(!showMobileMenu)}>
                        {showMobileMenu ? <FaTimes /> : 
                        <> {usuario.isLogged ? <>
                                <Space size='small'>                                    
                                    <Text strong style={{color:'#74b3ceff'}} className='icon-bars'><UserOutlined />{usuario.userName}</Text> 
                                    < FaBars />
                                </Space>
                            </>:< FaBars />  }</>}
                    </MobileIcon>

                    <Menu open={showMobileMenu}>
                    
                        <MenuItem>
                            <MenuItemLink to='/mundial' onClick={() => setShowMobileMenu(!showMobileMenu)}>
                                <div>
                                <FaHome />
                                <NavLink to='/mundial'> Copa America </NavLink>
                                </div>
                            </MenuItemLink>
                            
                        </MenuItem>
                        <MenuItem>
                            <MenuItemLink onClick={() => usuario.isLogged ? null:setShowMobileMenu(!showMobileMenu) }>
                                <div>
                                    {!usuario.isLogged && <FaUserAlt />}
                                    {usuario && usuario.token !== undefined && usuario.isLogged ? 
                                        <UsuarioDropDown logOut={logOut}/>
                                        : <NavBtnLink to='/login'> Iniciar Sesion</NavBtnLink>}
                                </div>
                            </MenuItemLink>
                            
                        </MenuItem>
                        
                        
                    </Menu>
                </IconContext.Provider>
            </NavWrapper>
        </NavbarContainer>
        
    </>
  )
}

export default Navbar;