import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const useBeginCompetition = () => {    

    const competition = useSelector(state => state.competitionsStore.competition);

    if (!competition) {
        return false;
    }

    // Verificar si el usuario tiene el rol de 'admin'
    // return !dayjs('2024-06-21 21:00').isBefore(dayjs(competition?.startDate));
    return !dayjs().isBefore(dayjs(competition?.startDate));
};

export default useBeginCompetition;
