import React from 'react';
import {Row,Col, Button} from 'antd';
import './Home.css';
import HomeMundial from '../Mundial/Templates/HomeMundial';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Home = () => {
  const navigate = useNavigate();
  
  const usuario = useSelector(state => state.usuarioStore.usuario);
    
  const irARegisto = (comp) => {
    // console.log(comp);
    navigate('/registro');
  };
  return(
    <>
      {!usuario?.isLogged && 
        <Row className='mt-50' justify={'center'}>
          <Col xs={12} sm={12} md={4}>
            <Button onClick={irARegisto} className='w-100'>
              Crear mi cuenta
            </Button>
          </Col> 
        </Row>
      }
      <Row  style={{marginTop:'50px'}}>
        <Col span={24}>
          <HomeMundial />
        </Col>                
      </Row>
    </>
    )
}



export default Home