import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center'
    }}>
      <h1 style={{ fontSize: '5rem', marginBottom: '2rem' }}>404</h1>
      <p style={{ fontSize: '1.5rem', marginBottom: '2rem' }}>Lo sentimos, la página que estás buscando no existe.</p>
      <Link to="/" style={{ fontSize: '1.2rem', textDecoration: 'none' }}>Volver al inicio</Link>
    </div>
  )
}

export default NotFound