import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
//contenedor de la barra
export const NavbarContainer = styled.nav`
  width: 100%;
  position:sticky;
  height: 100px;
  top:0;
  background-color: rgba(23, 42, 58, .7);
  z-index: 99;
  `; 

//contenido de la barra
export const NavWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
`;
//menu mundial usuario
export const Menu = styled.ul`
  height: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;

  @media screen and (max-width: 890px) {
    background-color: rgb(1 1 4 / 75%);
    position: absolute;
    top: 100px;
    left: ${({ open }) => (open ? "0" : "-100%")}; //Import
    width: 98%;
    border: 1.5px solid rgba(71, 122, 138,0.8);
    height: 40vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: 0.5s all ease;
    border-radius: 8em;
    margin:2px;

  }
`;
//contenenido del menu mundial usuario
export const MenuItem = styled.li`
  height: 100%;
  @media screen and (max-width: 890px) {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
//su puta madre no se que pollas sea
export const MenuItemLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5rem 1.5rem;
  color: rgba(116, 179, 206, 1);
  font-size: .8rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s all ease;
  &.active {
    color: #15cdfc;
  }
  &:hover {
    color: rgba(62, 120, 178, 1);
    transition: 0.5s all ease;
    div {
      svg {
        fill: #23394d;
      }
    }
  }
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      display: none;
      fill: rgba(116, 179, 206, 1);
      margin-right: 1.5rem;
    }
    a{
      svg {
        display: block;
        margin-right: 0;
      }
    }
  }
  @media screen and (max-width: 890px) {
    width: 100%;
    div {
      width: 30%;
      justify-content: left;
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 880px) {
    div {
      width: 40%;
      justify-content: left;
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 500px) {
    div {
      width: 60%;
      justify-content: left;
      svg {
        display: flex;
      }
    }
  }
  @media screen and (max-width: 260px) {
    div {
      width: 100%;
      justify-content: left;
      svg {
        display: flex;
      }
    }
  }
`;
//x de la esquina
export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 890px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      fill: rgba(116, 179, 206, 1);
      margin-right: 2rem;
    }
    .icon-bars svg{
      margin-right: 5px;
    }
  }
`;
/* -------------------------------------- */

//letras de la nav como "mundial"
export const NavLink = styled(Link)`
  color: rgba(116, 179, 206, 1);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 10px;
  height: 100%;
  cursor: pointer;
  font-size:1.2em;
  &.active {
    color: #15cdfc;
    text-decoration: overline;;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    color: rgba(0, 75, 168, 1);
  }
  @media screen and (max-width: 890px) {
    font-size:2em;
  }
`;
//letra del mundial a saber que mas

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: rgba(0, 67, 70, .8);
  padding: 10px 22px;
  color: rgba(116, 179, 206, 1);
  outline: none;
  border: none;
  font-size:1.2em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(23, 42, 58, .8);
    color:  rgba(116, 179, 206, 1);
  }
  @media screen and (max-width: 890px) {
    font-size:1.8em;
    padding: 4px 5px;
  }
`;