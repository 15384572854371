import React,  { useEffect}from 'react';
import { Button, Form, Input, Row,Col, Spin,notification,Typography} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import './Login.css'
import { useSelector,useDispatch} from 'react-redux';
import {login, resetUsuario,setLoading} from '../../redux/login/usuarioSlice'
import { UserOutlined,KeyOutlined } from '@ant-design/icons';
const { Title } = Typography;
function Login() {

  const navigate = useNavigate() // esto en lugar de history en esta version
  const dispatch = useDispatch();

  const usuario = useSelector(state => state.usuarioStore.usuario);
  const loading = useSelector(state => state.usuarioStore.loading);


  useEffect(() => {
    //console.log('hola'); 
    dispatch(setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {    
    if(usuario.token !== null && usuario.token !== undefined){
      notification.success({
        className:'notification-custom',
        message: 'Bienvenido',
        description: usuario.userName
      });
      window.localStorage.setItem('usuarioNks',JSON.stringify(usuario))
      navigate('/')
    }
    else if(usuario.token === null && usuario.isLogged === false ){
      notification.error({
        className:'notification-custom',
        message: '',
        description: usuario.message,
      });
      dispatch(resetUsuario());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usuario])

  const irARegistro = () => {
    navigate('/registro')
  }

  const irAForgot = () => {
    navigate('/forgot')
  }

  const onFinish = (values) => {
    let data = JSON.stringify({
      username:values.username,
      password: values.password
    });
    // console.log(data)
    dispatch(login(data))
  };

  const usernameValidator = (_, value) => {
    if (!value || /^[a-zA-Z0-9_]*$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('El nombre de usuario no debe contener espacios ni acentos'));
  };

  return (
    <Content className='mt-50'>
      <Spin spinning={loading}>
        <Row justify="center">
          <Col xs={22} sm={18} md={12} lg={8}>
            <div className="login-container">
              <Title level={2} className="login-title">Iniciar Sesión</Title>
              <Form
                name="login"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                className="formulario-login"
              >
                <Form.Item label="Nombre de usuario" name="username" rules={[
                    { required: true, message: 'Ingresa nombre de usuario' },
                    { validator: usernameValidator },]}>
                  <Input allowClear prefix={<UserOutlined />} />
                </Form.Item>

                <Form.Item label="Contraseña" name="password"
                  rules={[{ required: true, message: 'Ingresa contraseña' }]}
                >
                  <Input.Password allowClear prefix={<KeyOutlined />} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="btn-login" block >
                    Iniciar
                  </Button>
                </Form.Item>

                <Row justify="space-between">
                  <Col>
                    <Button type="link" onClick={() => irAForgot()} className='btn-link'>
                      Olvidé mi contraseña
                    </Button>
                  </Col>
                  <Col>
                    <Button type="link" onClick={() => irARegistro()} className='btn-link'>
                      Registrarme
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Spin>
    </Content>
       
  )
}

export default Login