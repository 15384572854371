import React,  { useEffect}from 'react';
import { Button, Select, Form, Input, Row,Col,Spin,notification,Typography } from 'antd';
import { UserOutlined,KeyOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import './Login.css'
import { useSelector,useDispatch} from 'react-redux';
import {registrarUsuario,setNewUsuario} from '../../redux/login/usuarioSlice'
import useIsAdmin from '../hooks/useIsAdmin';

const { Option } = Select;
const { Title } = Typography;

function Registro() {

  const dispatch = useDispatch();
  const navigate = useNavigate() // esto en lugar de history en esta version
  const isAdmin = useIsAdmin();

  const usuarioStore = useSelector(state => state.usuarioStore);

  const validateMessages = {
    required: '${label} is required!',
    // types: {
    //   email: '${label} is not a valid email!',
    // },
  };

  const onFinish = (values) => {    
    let newData = {};
    let newData2 = {estatus:0,roles:['mund'],name:values?.username,email:values?.username+'@nks.com',...values};
    if(!isAdmin){
      newData = {estatus:0,roles:['mund'],...newData2}
    }    
    dispatch(registrarUsuario( isAdmin ? values : newData));
  };



  useEffect(() => {    
    if(usuarioStore.newUsuario.id !== null && usuarioStore.newUsuario.id !== undefined){
      notification.success({
        className:'notification-custom',
        message: 'Usuario creado',
      });
      dispatch(setNewUsuario({}));
      navigate('/login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usuarioStore.newUsuario])

  const usernameValidator = (_, value) => {
    if (!value || /^[a-zA-Z0-9_]*$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('El nombre de usuario no debe contener espacios ni acentos'));
  };

  return (
        <Content className='mt-50'>
          <Spin spinning={usuarioStore.loading}>
            <Row justify="center" >
                <Col xs={22} sm={18} md={12} lg={8}>
                  <div className="login-container">
                    <Title level={2} className="login-title">Registro</Title>
                      <Form
                        name="registro"
                        onFinish={onFinish}
                        layout={'vertical'}
                        validateMessages={validateMessages}
                        className='formulario-login'
                      >
                        <Form.Item label="Nombre de usuario (sin espacios)" name="username" rules={[
                            { required: true, message: 'Ingresa nombre de usuario' },
                            { validator: usernameValidator }
                          ]}
                        >
                          <Input allowClear={true} placeholder={'Usuario'}  prefix={<UserOutlined />}/>
                        </Form.Item>

                        {/* <Form.Item label='Apodo' name="name" >
                          <Input allowClear={true} placeholder={'Apodo'} />
                        </Form.Item> */}

                        {/* <Form.Item label='Correo' name='email'  rules={[{ required: true,type: 'email' }]}>
                          <Input  placeholder={'Correo'}/>
                        </Form.Item> */}

                        {isAdmin &&
                          <Form.Item label='Estatus' name='estatus' >
                            <Select
                                defaultValue="0"                        
                                options={[
                                  {
                                    value: '0',
                                    label: 'Pendiente: Pago y marcadores',
                                  },
                                  {
                                    value: '1',
                                    label: 'Completo',
                                  },
                                  {
                                    value: '2',
                                    label: 'Pendiente: Pago',
                                  },
                                  {
                                    value: '3',
                                    label: 'Pendiente: Marcadores',
                                  },
                                ]}
                            />
                          </Form.Item>
                        }

                        <Form.Item label="Contraseña"  name="password"  rules={[{ required: true, message: 'Ingresa tu contraseña' }]}  >
                          <Input.Password allowClear={true}  placeholder={'Contraseña'} prefix={<KeyOutlined />}/>
                        </Form.Item>

                        {
                          isAdmin &&
                            <Form.Item name="roles" label="Roles"
                              rules={[{ required: true, message: 'Please select your role!', type: 'array' }]}
                            >
                              <Select mode="multiple" placeholder="Roles">
                                <Option value="admin">Admin</Option>
                                <Option value="mod">Mod</Option>
                                <Option value="user">User</Option>
                                <Option value="mund">Mundial</Option>
                              </Select>
                            </Form.Item>
                        }
                        <Form.Item>
                          <Button type="primary" htmlType="submit" className="btn-login" block >
                            Registrar
                          </Button>
                        </Form.Item>
                      </Form>
                  </div>
                </Col>
            </Row>
          </Spin>
        </Content>      
  )
}

export default Registro