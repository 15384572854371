export const SCORE_TYPE= {user:'USER',final:'FINAL'}
export const STAGE={group:'GROUP_STAGE',finals:'FINALS'}
export const LABEL_REGLAS= `
<--------------------- Información ---------------------> <br />

La quiniela se divide en dos fases (fase de grupos y finales).

El ganador de cada fase se lleva todo el dinero.

FASE DE GRUPOS.
Cada participante registrará sus marcadores en una de las dos formas disponibles (Hoja impresa, hoja de calculo en drive), las cuales se compartirán por este grupo.

Para el caso del registro por hoja de calculo en drive necesitare que me compartan su *correo* de gmail para compartirles la tabla.
Para el registro en hoja impresa mandare el formato  y cada quien puede imprimirlo y llenarlo. Mañana sábado dejare unas copias en casa de mi abuelita para quien este cerca y quiera usar este método.
Se tendrá una semana para el registro, este se termina el *sábado 19 a las 11:59 pm*. Después de ese momento no se recibirán mas  registros.
Para quienes lo hagan por drive los permisos de edición se quitaran igualmente el *sábado 19 a las 11:59 pm*.

*IMPORTANTE*
Mandar sus tablas por mensaje privado solo a mi y NO en el grupo.




    `;
export const LABEL_REGLAS2 =
<>
    <h1 style={{fontSize: '1.5em',texAling:'center'}}>Informacion</h1> 
    <p>La quiniela se divide en dos fases (fase de grupos y finales).</p><br />
    <p>El ganador de cada fase se lleva todo el dinero de esa fase.</p><br />
    <h1>FASE DE GRUPOS.<br /> <br /></h1>
    <p>
    
    Cada participante registrará sus marcadores en una de las dos formas disponibles (Hoja impresa, hoja de calculo en drive), las cuales se compartirán por este grupo.
    <br /><br />
    Para el caso del registro por hoja de calculo en drive necesitare que me compartan su <b>Correo de gmail</b> para compartirles la tabla.
    Para el registro en hoja impresa mandare el formato  y cada quien puede imprimirlo y llenarlo.<br /><br />
    Se tendrá una semana para el registro, este se termina el <b>sábado 19 de noviembre a las 11:59 pm</b>. Después de ese momento no se recibirán mas  registros.
    Para quienes lo hagan por drive los permisos de edición se quitaran igualmente el  <b>sábado 19 de noviembre a las 11:59 pm</b>.
    </p><br />
    <h1>IMPORTANTE</h1><br />
    Mandar sus tablas por mensaje privado solo a mi y NO en el grupo.<br /><br />
    <h1>REGLAS PARA PUNTAJE</h1><br />
    <ul style={{listStyleType:'disc'}}>
        <li>Se registrará el marcador por el que apuestan para cada partido. Los marcadores no enviados o no registrados antes del <b>sábado 19 de noviembre a las 11:59 pm</b> no contaran</li>
        <li> Se asignara 1 PUNTO si se acierta al ganador</li>
        <li> Se asignaran 2 PUNTOS si se acierta al ganador Y también al marcador</li>
        <li> Se asignaran 0 PUNTOS si no aciertan en ninguno de los dos casos</li>
        <li> Quien tenga mas puntos al final de cada fase gana</li>
        <li> En caso de existir EMPATE el premio se reparte entre los participantes con el puntaje empatado mas alto</li>
    </ul>
    <p>Nota: Los detalles de la segunda fase se darán despues.</p>
</>


export const LABEL_REGLAS_CA2024 =
<>
    <h1 className="mt-20">Informacion Copa America 2024</h1> 
    <p>La quiniela se divide en dos fases (fase de grupos y finales).</p><br />
    <p>El ganador de cada fase se lleva todo el dinero de esa fase.</p><br />
    <h2>FASE DE GRUPOS<br /> <br /></h2>
    <p>
    
    Cada participante registrará sus marcadores mediante la pagina web, accediendo con su usuario previamente creado
    <br /><br />
    El registro de marcadoresse cierra el <b>Jueves 20 de Junio a las 19:59 pm</b>. Después de ese momento no se recibirán mas registros.
    </p><br />
    <h2> <strong>IMPORTANTE</strong></h2><br />
    Revisar que se han llenado todos sus marcadores correctamente y guardar una captura de pantalla de los mismos en caso de cualquier aclaracion.<br /><br />
    <h2 style={{texAling:'center'}}>REGLAS PARA PUNTAJE</h2>
    <ul style={{listStyleType:'disc'}}>
        <li>Se registrará el marcador por el que apuestan para cada partido. Los marcadores no enviados o no registrados antes del <b>Jueves 20 de Junio a las 19:59 pm</b> no contaran</li>
        <li> Se asignara 1 PUNTO si se acierta al ganador</li>
        <li> Se asignaran 2 PUNTOS si se acierta al ganador Y también al marcador</li>
        <li> Se asignaran 0 PUNTOS si no aciertan en ninguno de los dos casos</li>
        <li> Quien tenga mas puntos al final de cada fase gana</li>
        <li> En caso de existir EMPATE el premio se reparte entre los participantes con el puntaje empatado mas alto</li>
    </ul>
    <p>Nota: Los detalles de la segunda fase se darán despues.</p>
</>