import React, { useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import { Col, Row ,Dropdown, Space,Menu} from 'antd';
import './Partidos.css';
import { PoweroffOutlined,DownOutlined, SmileOutlined } from '@ant-design/icons';
import TablasResultados from './tablas/TablasResultados';
import HeaderOpciones from './configuracion/HeaderOpciones.js';
import { LABEL_REGLAS_CA2024 } from './ConstantesMundial';

const ContentComponent = () => {
  
  useEffect(() => {    
}, []);

const usuario = useSelector(state => state.usuarioStore.usuario);
const [allowedRole,setAllowedRole] = useState(['admin']);


  return (
    <>
        <Row justify="center" className='contenedorPartidos'>
          <Col xs={24} sm={24} md={{ span: 24}} >
                <HeaderOpciones 
                  txtButton = {'Consultar reglas'} 
                  txtAdd = {'Agregar participantes'}
                  showButton = {usuario.isLogged && usuario?.roles?.find(role => allowedRole?.includes(role.name)) !== undefined}
                  txtRules = {LABEL_REGLAS_CA2024} />
            </Col> 
            <Col xs={24} sm={24} md={{ span: 24}} >
                <TablasResultados />  
            </Col>   
                    
        </Row>
    
    </>
  )
}

export default ContentComponent