import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    competitions: [],
    competition:{},
    loading: false,
    idMensaje: null,
    mensaje: null,
    error: null,
    stage: null,
};

const URL_API_GET_COMPETITION = '/api/competitions/getCompetition';
const URL_API_GET_COMPETITIONS = '/api/competitions';


export const CompetitionSlice = createSlice({
    name: 'competitionS',
    initialState,
    reducers: {
        getCompetition: (state,action) => {
            state.competition = action.payload;
        },
        getCompetitions: (state,action) => {
            state.competitions = action.payload;
        },
        setLoading: (state,action) => {
            state.loading = action.payload;
        },
        setError: (state,action) => {
            state.error = action.payload;
        },
        resetCompetitionS: () => {
            return initialState;
        },  
        setStage: (state,action) => {
            state.stage = action.payload;
        },
    }    
});

export const { getCompetition,getCompetitions ,setLoading,setError,resetCompetitionS,setStage} = CompetitionSlice.actions;

export default CompetitionSlice.reducer;



// ******************************************************************************
export const setCompetition =  (params) =>  async (dispatch) => {
    dispatch(getCompetition(params));
}
export const setDataStage =  (params) =>  async (dispatch) => {
    dispatch(setStage(params));
}
export const obtenerCompeticion =  (params) =>  async (dispatch) => {
    dispatch(setLoading(true));

    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_COMPETITION,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(getCompetition(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}

export const obtenerCompeticiones =  (params) =>  async (dispatch) => {
    dispatch(setLoading(true));

    let config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_COMPETITIONS,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(getCompetitions(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}



