import React, { useEffect } from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector,useDispatch} from 'react-redux';


const RequireAuth = ({ allowedRoles }) => {

    const location = useLocation();

    const usuario = useSelector(state => state.usuarioStore.usuario);

    useEffect(() => {
        //console.log( usuario?.roles)
        //console.log(allowedRoles)
    }, [])
    
    return (
        usuario?.roles?.find(role => allowedRoles?.includes(role.name)) 
            ? <Outlet />
            : usuario?.isLogged
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;