import React, { useEffect} from "react";
import { Row,Col,Form,notification,Modal,Avatar,Button, InputNumber,Spin,Space} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {guardarMarcador,cleanError,actualizarMarcador,cleanMensaje} from '../../../redux/Mundial/scoreSlice';
import {obtenerPartidosMarcador} from '../../../redux/Mundial/matchSlice';
import { SCORE_TYPE } from "../ConstantesMundial";


const ConfigScoreModal = (props) => {    
    const { isVisible,record ,participante,marcadoresFinales,defaultLogo} = props;

    const dispatch = useDispatch();
    const [formScore] = Form.useForm();

    const faseActual = useSelector(state => state.competitionsStore.stage);
    const scoreStore = useSelector((state) => state.scoreStore);
    
    useEffect(() => {
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
       //console.log(isVisible)
        if(isVisible){
            formScore.setFieldsValue({
                'homeScore': record?.score?.home,
                'awayScore':record?.score?.away
            });
        }
    // eslint-disable-next-line
    }, [isVisible]);

    useEffect(() => {        
        if(scoreStore.idMensaje){
            if(scoreStore.idMensaje === 1){
                notification.success({
                    className:'notification-custom',
                    message: scoreStore.mensaje                    
                  });
            }
            else {
                notification.error({
                    className:'notification-custom',
                    message: scoreStore.mensaje
                  });
            } 
            dispatch(cleanMensaje());
            let data = {
                usuario: marcadoresFinales ? "":participante.username,
                grupo: "",
                stage:faseActual,
                scoreType: marcadoresFinales? SCORE_TYPE.final:SCORE_TYPE.user
            };
           //console.log(data)
            dispatch(obtenerPartidosMarcador(data)); 
            onCancel();
        }
    // eslint-disable-next-line
    }, [scoreStore.idMensaje]);

    useEffect(() => {
         if(scoreStore.error){
            notification.error({
                className:'notification-custom',
                message: scoreStore.error.message
              });
             dispatch(cleanError());
             onCancel();
             
         }
     // eslint-disable-next-line
     }, [scoreStore.error]);

    //Funciones
    const onCancel = () => {
        props.cerrarModal({confirmar:false});
    }
    const guardar = () => {
       //console.log(record)
       //console.log(participante)
        
        let formInvalid = formScore.getFieldsError().some((item) => item.errors.length > 0);
        if(!formInvalid){
            let winner=null;
            if(formScore.getFieldsValue().homeScore+"" === formScore.getFieldsValue().awayScore+""){
                winner = 'E';
            } else if(formScore.getFieldsValue().homeScore+"" > formScore.getFieldsValue().awayScore+""){
                winner = 'L';
            } else {
                winner = 'V';
            }
            let data = {
                scoreId: record.score !== undefined? record.score?._id: null,
                winner: winner,
                home:formScore.getFieldsValue().homeScore+"",
                away: formScore.getFieldsValue().awayScore+"",
                points:0,
                type: marcadoresFinales ? SCORE_TYPE.final:SCORE_TYPE.user,
                _user: marcadoresFinales ? null:participante._id,
                _match:record._id
            };
           //console.log(data) 
            
            if(record.score !== undefined){                
                dispatch(actualizarMarcador(data));
            }
            else{ 
                dispatch(guardarMarcador(data));
            }
            
        }else{
            formScore.validateFields();
        }
    }

    return (
        <Modal
            title={'Configurar marcador'}
            open={isVisible} width={550} 
            destroyOnClose={true}
            onCancel={onCancel}
            footer={null}
            closable={true}
            centered
            maskClosable={false} >
            <Spin spinning={scoreStore.loading}>
                <Form
                    form={formScore}
                    layout="vertical"
                    name="formTipoAspirante"
                    autoComplete="off"
                    onFinish={guardar}
                    className='mt-50'
                >
                    <Row justify={'center'} className="mod-score">
                        <Col xs={10} sm={10} md={{ span:10 }} style={{textAlign:'left'}}>                            
                            <Space size={'middle'}><Avatar src={record?.homeTeam?.logo ?? defaultLogo} />{record?.homeTeam?.name ?? 'Por definirse'}</Space>
                        </Col>                          
                        <Col xs={10} sm={10} md={{ span:10 }} style={{textAlign:'right'}}>
                            <Space size={'middle'}> {record?.awayTeam?.name ?? 'Por definirse'}<Avatar src={record?.awayTeam?.logo ?? defaultLogo} /></Space>
                        </Col>
                    </Row>
                    <Row justify={'center'} className="mt-20">
                        <Col   Col xs={10} sm={10} md={{ span:10 }} style={{textAlign:'left'}}>
                            <Form.Item name="homeScore"
                                    rules={[{ required: true, message: 'Dato requerido' }]}
                                >
                                    <InputNumber min={0} max={100} style={{width:'80%'}} className="txt-center"/>
                            </Form.Item>
                        </Col>
                        <Col xs={2} sm={2} md={{ span:2 }} style={{textAlign:'center'}}>VS</Col>
                        <Col xs={{span:10, offset:0}} sm={{span:10}} md={{ span:10, offset:0}} style={{textAlign:'right'}}>
                            <Form.Item name="awayScore"
                                    rules={[{ required: true, message: 'Dato requerido' }]}
                                >
                                    <InputNumber min={0} max={100} style={{width:'80%'}} className="txt-center"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} style={{textAlign: "center"}}>
                            <Button 
                                type="primary" htmlType="submit"
                                className="ant-btn ant-btn-primary"
                            >Guardar</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}
export default ConfigScoreModal;