import React, { useEffect} from "react";
import { Row,Form,notification,Modal,Button, Input,Select,Spin} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {actualizarParticipante,obtenerParticipantes,cleanError,cleanMensaje} from '../../../redux/Mundial/participantsSlice';


const EditPartipantModal = (props) => {
    
    const { isVisible,participante} = props;

    const faseActual = useSelector(state => state.competitionsStore.stage);

    const dispatch = useDispatch();

    const participantStore = useSelector((state) => state.participantsStore);
    

    const [formParticipant] = Form.useForm();

    useEffect(() => {
        if(isVisible){
            formParticipant.setFieldsValue({
                'name': participante?.name,
                'estatus':participante?.estatus
            });
        }
    // eslint-disable-next-line
    }, [isVisible]);

    useEffect(() => {
        //console.log(participantStore.idMensaje)
        //console.log(tipoTabla)
        //console.log(participante)
         if(participantStore.idMensaje){
             if(participantStore.idMensaje === 1){
                 notification.success({
                    className:'notification-custom',
                     message: participantStore.mensaje
                   });
             }
             else {
                 notification.error({
                    className:'notification-custom',
                     message: participantStore.mensaje
                   });
             } 
             let data = JSON.stringify({
              stage: faseActual
            });
             dispatch(cleanMensaje());
             dispatch(obtenerParticipantes(data));
             onCancel();
             
         }
     // eslint-disable-next-line
     }, [participantStore.idMensaje]);
 
     useEffect(() => {
         //console.log(participantStore.idMensaje)
         //console.log(tipoTabla)
         //console.log(participante)
          if(participantStore.error){
             notification.error({
                  className:'notification-custom',
                 message: participantStore.error.message
               });
              dispatch(cleanError());
              onCancel();
              
          }
      // eslint-disable-next-line
      }, [participantStore.error]);

    //Funciones
    const onCancel = () => {
        props.cerrarModal({confirmar:false});
    }
    const updateParticipante = (values) => {        
        let formInvalid = formParticipant.getFieldsError().some((item) => item.errors.length > 0);
        if(!formInvalid){
            let data = JSON.stringify({
                _id: participante._id,
                name: formParticipant.getFieldsValue().name+"",
                estatus:formParticipant.getFieldsValue().estatus+"",
                stage:faseActual
            });
            
        dispatch(actualizarParticipante(data));
            
        }else{
            formParticipant.validateFields();
        }
    }


    return (
        <Modal
            title={'Configurar participante'}
            width={400} open={isVisible} 
            onCancel={onCancel}
            footer={false}
            destroyOnClose={true}
            closable={true}
            maskClosable={false} 
            className= 'mod-partipant'>
            <Spin spinning={participantStore?.loading}>
                <Form
                    form={formParticipant}
                    layout="vertical"
                    name="formTipoAspirante"
                    autoComplete="off"
                    onFinish={updateParticipante}
                >

                    <Form.Item label='Nombre' name="name" rules={[{ required: true, message: 'Please input your username!' }]}  >
                      <Input allowClear={true} placeholder={'Usuario'} />
                    </Form.Item>

                    <Form.Item label='Estatus' name='estatus' >
                    <Select
                        defaultValue="0"
                        
                        options={[
                          {
                            value: '-1',
                            label: 'No participando',
                          },
                          {
                            value: '0',
                            label: 'Pendiente: Pago y marcadores',
                          },
                          {
                            value: '1',
                            label: 'Completo',
                          },
                          {
                            value: '2',
                            label: 'Pendiente: Pago',
                          },
                          {
                            value: '3',
                            label: 'Pendiente: Marcadores',
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item >
                      <Row justify={'center'}>
                        <Button className='btn-login' htmlType="submit">
                          Guardar
                        </Button>
                      </Row>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}
export default EditPartipantModal;