import { HashRouter } from 'react-router-dom';
import { React, useState} from 'react';
import {ConfigProvider, theme} from 'antd';
import './css/App.css';
import { Provider } from 'react-redux';
import store,{persistor} from './redux/store';
import AppRouter from './router/AppRouter';
import esEs from "antd/lib/locale/es_ES";
import dayjs from "dayjs";
import 'dayjs/locale/es';
import { PersistGate } from 'redux-persist/integration/react';
import NavbarMenu from './components/Navbar/Navbar';
import { tokenTheme,compomentsTheme } from './css/nksTheme';
// import * as constHome from './components/home/Constantes';
// // import { SunOutlined,MoonOutlined } from '@ant-design/icons';
// import { EditOutlined,SettingOutlined} from '@ant-design/icons';

dayjs.locale('es');

const App = () => {
  // const [darkTheme,setDarkTheme] = useState(true);
  // const changeTheme = () => {
  //   setDarkTheme (!darkTheme);
  // }

  return (
    <ConfigProvider locale={esEs} theme={{
      token: tokenTheme,
      components: compomentsTheme,
      algorithm: [theme.darkAlgorithm],
    }} >
    <Provider store={store} >
      <PersistGate loading={null} persistor={persistor}>
        {/* <div id={darkTheme ? constHome?.DARK_THEME : constHome?.LIGHT_THEME}> */}
        {/* <Switch
          checkedChildren={<SettingOutlined />}
          unCheckedChildren={<EditOutlined />}
          checked={darkTheme}
          onChange={changeTheme}
        /> */}
          <HashRouter>
          {/* <AppMenu />  */}
          <NavbarMenu />
          <AppRouter />
          </HashRouter>
        {/* </div> */}
      </PersistGate>
    </Provider>
    </ConfigProvider>
      
  );
}

export default App;
