import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    teams: [],
    team:{},
    loading: false,
    mensaje: null,
    error: null,
};

const URL_API_GET_TEAMS = '/api/teams';


export const TeamSlice = createSlice({
    name: 'teamS',
    initialState,
    reducers: {
        setTeams: (state,action) => {
            state.teams = action.payload;
        },
        setTeam: (state,action) => {
            state.team = action.payload;
        },
        setNewTeam: (state,action) => {
            state.newTeam = action.payload;
        },
        updateTeam: (state,action) => {
            return {...state, ...action.payload}
        },
        setLoading: (state,action) => {
            state.loading = action.payload;
        },
        setError: (state,action) => {
            state.error = action.payload;
        },
        resetTeam: () => {
            return initialState;
        }   
    }    
})

export const {setTeams, setTeam, setNewTeam, updateTeam,setLoading,setError,resetTeam} = TeamSlice.actions;

export default TeamSlice.reducer;

// ******************************************************************************

export const obtenerEquipos =  (data) =>  async (dispatch) => {
    dispatch(setLoading(true));
   //console.log(process.env.REACT_APP_BACKEND_URL)
    var config = {
        method: 'get',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_GET_TEAMS,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
      };
    await axios(config)
    .then((response) => {
       //console.log(response);
        dispatch(setTeams(response.data.teams));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}