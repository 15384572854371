import { createSlice } from "@reduxjs/toolkit";

const initialState = [
    {
        id:"1",
        title: "task1",
        description: "desc",
        completed: false
    },
    {
        id:"2",
        title: "task2",
        description: "desc",
        completed: false
    },
    {
        id:"3",
        title: "task3",
        description: "desc",
        completed: false
    }
];

export const taskSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        addTask: {
            reducer(state,action) {
                state.push(action.payload)
            }
        },
        deleteTask: (state,action) => {
            
            const taskFound = state.find( task => task.id === action.payload)
           //console.log(taskFound)
            if(taskFound){
                state.splice(state.indexOf(taskFound,1))
            }
                //state.push(action.payload)
            }
        ,
        editTask: (state,action) => {
            const {id,title,description} = action.payload
            const taskFound = state.find( task => task.id === id)
            if(taskFound){
                taskFound.title = title
                taskFound.description = description
            }
            }
        ,
        

    }
})

export const {addTask,deleteTask,editTask} = taskSlice.actions

export default taskSlice.reducer