import { Row,Col,Radio} from 'antd';
import { useSelector,useDispatch} from 'react-redux';
import { setDataStage } from '../../../redux/Mundial/competitionsSlice';
import './Templates.css'
import { STAGE } from '../ConstantesMundial';

export const GameFase =(props) => {   
    const faseActual = useSelector(state => state.competitionsStore.stage);
    const dispatch = useDispatch();

    const onChange = (e) => {
        dispatch(setDataStage(e.target.value));
    };  

    return (
        <>       
            <Row justify='center' className='mt-50'>
                <Col xs={22} sm={12}  md={{ span: 8}} className='radio-fase'>
                    <Radio.Group onChange={onChange} value={faseActual} optionType="button"  buttonStyle="solid" size="large">
                        <Radio value={STAGE.group}>Fase de grupos</Radio>
                        <Radio value={STAGE.finals}>Finales</Radio>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    )
}

export default GameFase;