import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const initialState = {
    scores: [],
    score:{},
    loading: false,
    idMensaje: null,
    mensaje: null,
    error: null,
};

const URL_API_SAVE_SCORES = '/api/scores/saveScore';
const URL_API_UPDATE_SCORES = '/api/scores/updateScore';



export const ScoreSlice = createSlice({
    name: 'scoreS',
    initialState,
    reducers: {
        setSaveScore: (state,action) => {
            state.idMensaje = action.payload.idMessage;
            state.mensaje = action.payload.message;
        },
        setLoading: (state,action) => {
            state.loading = action.payload;
        },
        setError: (state,action) => {
            state.error = action.payload;
        },
        setCleanMensaje: (state,action) => {
            state.idMensaje = null;
            state.mensaje = null;
        },
        resetScoreS: () => {
            return initialState;
        }   
    }    
})

export const { setSaveScore, setLoading,setError,resetScoreS,setCleanMensaje} = ScoreSlice.actions;

export default ScoreSlice.reducer;

// ******************************************************************************
export const cleanMensaje = () => async (dispatch) => {
    dispatch(setCleanMensaje());
}

export const cleanError = () => async (dispatch) => {
    dispatch(setError(null));
}

// ******************************************************************************

export const guardarMarcador =  (params) =>  async (dispatch,getState) => {
    dispatch(setLoading(true));
    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_SAVE_SCORES,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': getState().usuarioStore.usuario?.token
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(setSaveScore(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}

export const actualizarMarcador =  (params) =>  async (dispatch,getState) => {
    dispatch(setLoading(true));

    let config = {
        method: 'post',
        url: process.env.REACT_APP_BACKEND_URL + URL_API_UPDATE_SCORES,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': getState().usuarioStore.usuario?.token
        },
        data : params
    };
    await axios(config)
    .then((response) => {
        dispatch(setSaveScore(response.data));
        dispatch(setLoading(false));
    })
    .catch ((error) => {
        console.log(error)
        dispatch(setError(error.response.data));
        dispatch(setLoading(false));
    })
}
